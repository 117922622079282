import { ThemeProvider } from '@emotion/react'
import mainTheme from '../css/themes/mainTheme'
import { useEffect, useState, useCallback } from "react"
import { Button, Box, CssBaseline, CircularProgress, TextField, MenuItem } from '@mui/material'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getRole } from '../redux/dataslices/tokenSlice'
import { store } from '../redux/store'
import i18n from "i18next"

export default function UserSettingsPanel(props) {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [subscriptionPurgeAlertOpened, setSubscriptionPurgeAlertOpened] = useState(false);
  const role = useSelector(getRole)

  const onClickReturnToDashboard = () => {
    savePageData("/dashboard")
  }

  const savePageData = useCallback((redirect = null) => {
    props?.updateUserSettings({ 
      id_owner: props?.id_owner,
      user_language: props?.userSettingsData?.user_language
    },redirect)
    setLoading(false)
  },[props])

  const onPageUnload = useCallback(() => {
    savePageData()
  },[savePageData])

  const handleSelectFieldEdit = (event) => {
    props.updateDataField(event.target.name, event.target.value)
  }
  
  useEffect(() => {
    window.addEventListener("beforeunload", onPageUnload)
    return () => {
      window.removeEventListener("beforeunload", onPageUnload)
    }
  }, [onPageUnload])
  
  const subscriptionPurgeAlertCancel = () => {
    setSubscriptionPurgeAlertOpened(false)
  }

  const subscriptionPurgeAlertConfirm = () => {
    setSubscriptionPurgeAlertOpened(false)
    props?.purgeSubscriptions()
  }

  const onClickSubscriptionPurgeButton = () => {
    setSubscriptionPurgeAlertOpened(true)
  }

  return (
    <ThemeProvider theme={mainTheme}>
      <CssBaseline />
      <Box
        className="field-container"
        sx={{ ml: -0.5, mr: -0.5, mt: 1 }}>  
        <Dialog
          open={subscriptionPurgeAlertOpened}
          onClose={subscriptionPurgeAlertCancel}
          >
          <DialogTitle>
            {t("zzz_temp_purge_subscriptions")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t("zzz_temp_purge_subscriptions_message")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={subscriptionPurgeAlertCancel} autoFocus>{t("cancel")}</Button>
            <Button onClick={subscriptionPurgeAlertConfirm}>{t("zzz_temp_purge_subscriptions")}</Button>
          </DialogActions>
        </Dialog>

        <Box className="field-block">
          <TextField
            className={"resizableChild input-select"}
            select
            label={t("language")}
            id={"user_language"}
            name={"user_language"}
            onChange={handleSelectFieldEdit}
            value={props?.userSettingsData?.user_language ? props?.userSettingsData?.user_language : i18n.language}
            sx={{mt: 1, mb: 1, ml: 0.5, mr: 0.5, textAlign: "left", input: {textAlign: "left"}}}
          >
            {store.languages.map((language) => (
              <MenuItem
              value={language}
              key={language}>
                {t("language_select_" + language)}
              </MenuItem>
            ))}
          </TextField>
        </Box>
        {role === "super" || role === "admin" ?
        <Box className="field-block">
          <Button
            type="submit"
            variant="contained"
            color='secondary'
            onClick={() => onClickSubscriptionPurgeButton()}
            sx={{ mt: 1, mb: 1, ml: 0.5, mr: 0.5, borderRadius: '9px' }}
          >
            {loading ? (
              <CircularProgress color='inherit' size={32} />
            ) : (
              t('zzz_temp_purge_subscriptions')
            )}
          </Button>
        </Box>
        : <></> }
      </Box>
      <Box sx={{ height: '37px', mt: 1, mb: 1, textAlign: 'center', position: 'relative' }}>
        <Button
          type="submit"
          variant="contained"
          color='secondary'
          onClick={() => onClickReturnToDashboard()}
          sx={{ borderRadius: '9px', position: 'absolute', left: 0}}
        >
          {loading ? (
            <CircularProgress color='inherit' size={32} />
          ) : (
            t('return_to_dashboard')
          )}
        </Button>
      </Box>
    </ThemeProvider>
  )
}